function toggleFacet(event) {

   const facetContainer = document.querySelector(event.getAttribute('data-target-id'));

   const facetContent = facetContainer.querySelector('.magellan-facet-content-wrapper');

   const facetIcon = event.querySelector('.magellan-facet-icon');

   if (facetIcon) {
      facetIcon.classList.toggle('rotate');
   }

   if (facetContent) {
      facetContent.classList.toggle('closed');
   }

}

function toggleFacetChildren(element) {

   const childrenId = element.getAttribute('data-term-children-id')

   if(!childrenId) return;

   const checked = element.checked
   childrenId.split(',').forEach(id => {
      const facet = document.querySelector(`input[data-term-id='${id}']`);
      if (facet && facet.checked !== checked) {
         facet.click();
      }
   })

}

function toggleFacetItems(event, element) {

   let parentUl = element.parentElement.parentElement;

   if(parentUl) {
      let selected = parentUl.querySelectorAll('[type^="checkbox"]:checked');
      if(selected.length == 0) {
         let parentCheckbox = parentUl.previousElementSibling;
         parentCheckbox.querySelector('[type^="checkbox"]').checked = false;
      }
   }
   Liferay.Search.FacetUtil.changeSelection(event);
}

Liferay.ThemeDisplay = Liferay.ThemeDisplay || {};
Liferay.ThemeDisplay.toggleFacet = toggleFacet;
Liferay.ThemeDisplay.toggleFacetChildren = toggleFacetChildren;
Liferay.ThemeDisplay.toggleFacetItems = toggleFacetItems;